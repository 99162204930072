












































import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Ref('file-input') fileRef: any
  isDragging = false
  files = [] as any
  selectedFile: File = null as any
  @Prop({ default: false }) isError
  @Prop({ default: null }) uploadedFile
  @Prop({ default: 'File' }) label

  @Watch('uploadedFile', { immediate: true }) uploadedFileChanged(value) {
    this.selectedFile = value
  }

  removeFile() {
    this.fileRef.value = null
    this.onChange()
  }

  onChange() {
    this.files = [...this.fileRef.files]
    this.selectedFile = this.files?.length ? this.files[0] : null
    this.$emit('change', this.selectedFile)
  }

  dragover(e) {
    e.preventDefault()
    this.isDragging = true
  }

  dragleave() {
    this.isDragging = false
  }

  drop(e) {
    e.preventDefault()
    this.fileRef.files = e.dataTransfer.files
    this.onChange()
    this.isDragging = false
  }
  openFileInput() {
    this.fileRef?.click()
  }
}
